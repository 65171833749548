import React from 'react';

// import './styles.scss';

import {end} from '../../utils/beforeunload';
import strings from '../../utils/strings';
import ferdigLydDe from '../../assets/sounds/youth/de/testen-er-ferdig.mp3';
import AudioPlayer from '../AudioPlayer';

class Ferdig extends React.Component {
	componentDidMount() {
		end();
		window.localStorage.removeItem('authtoken-screening');
		setTimeout(function () {
			window.location = '/';
		}, 10000);
	}

	render() {
		return (
			<div>
				<h1 className="lit-page-title">{'Geschafft!'}</h1>
				{strings.getLanguage() ==='de' && (
					<p className="lit-page-description"> <AudioPlayer
					autoplay
					onErrorPlay={()=>{
						console.log('Something went wrong');
					}}
					inline
					src={ferdigLydDe}
				/>{strings.doneCongrats}</p>
				)}

				{strings.getLanguage() !=='de' && (
					<p className="lit-page-description"> {strings.doneCongrats}</p>
				)}
				
			</div>
		);
	}
}

export default Ferdig;
