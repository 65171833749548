import React from 'react';
import {connect} from 'react-redux';
import strings from '../../utils/strings';

import AudioPlayer from '../../components/AudioPlayer';

import previewVideoSe from './assets/tutorial.mp4';
import previewVideoDe from './assets/DE_Ordkjede.mp4';
import './styles.scss';
import {VARIATION} from '../../constants';
class Intro extends React.Component {
	constructor(props) {
		super(props);
		const variation = VARIATION;
		const language = strings.getLanguage();
		const previewVideoObj = {
			se: previewVideoSe,
			de: previewVideoDe,
		};
		const audio = require(`../../assets/sounds/${variation}/${language}/ordkjedetekstIntrosound.mp3`);
		this.video = previewVideoObj[language];
		this.audio = audio;
		this.videoRefView = React.createRef();
	}
	render() {
		const {onNextStep} = this.props;
		let videoRef;
		return (
			<div className="lit-intro">
				<p>
					<AudioPlayer 
					autoplay
					inline 
					onErrorPlay={()=>{
						console.log('Something went wrong');
					}}
					onStart={() => {
						const scrollActionTime = 23000;

						if (scrollActionTime) {
							setTimeout(() => {
								if (this.videoRefView.current) {
									this.videoRefView.current.scrollIntoView(
										{
											behavior: 'smooth',
											block: 'start',
										}
									);
									videoRef.play();
								}
							}, scrollActionTime);
						}
					}}
					onPause={() => {
							videoRef.play();
						}} src={this.audio} />
					{strings.ordkjedetestIntro}
				</p>
				<br/>
				
				<div className="lit-preview" ref={this.videoRefView}>
					<p className="lit-preview__label">{strings.howTo}</p>
					<video
						className="lit-preview__video"
						onClick={() => {
							videoRef.play();
						}}
						ref={(ref) => {
							videoRef = ref;
						}}
						src={this.video}
					/>
				</div>
				<br/>
				<p>{strings.ordkjedetestIntro2}</p>
				<p>{strings.subtestStarterTrial}</p>
				
				<button
					className="lit-btn bg-primary"
					onClick={() => onNextStep()}
				>
					{strings.showExamples}
				</button>
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps)(Intro);
