import audio1 from '../../../sounds/child/de/word/Boot.mp3';
/* import audio2 from '../../../sounds/child/de/word/Flotte.mp3';
import audio3 from '../../../sounds/child/de/word/Flob.mp3';
import audio4 from '../../../sounds/child/de/word/Segel.mp3';
import audio5 from '../../../sounds/child/de/word/Schiff.mp3'; */

import audio6 from '../../../sounds/child/de/word/Abfall.mp3';
/* import audio7 from '../../../sounds/child/de/word/Mull.mp3';
import audio8 from '../../../sounds/child/de/word/Abwasser.mp3';
import audio9 from '../../../sounds/child/de/word/Tonne.mp3';
import audio10 from '../../../sounds/child/de/word/Dreck.mp3'; */

import audio11 from '../../../sounds/child/de/word/brav.mp3';
/* import audio12 from '../../../sounds/child/de/word/artig.mp3';
import audio13 from '../../../sounds/child/de/word/leise.mp3';
import audio14 from '../../../sounds/child/de/word/streng.mp3';
import audio15 from '../../../sounds/child/de/word/gut.mp3'; */

import audio16 from '../../../sounds/child/de/word/Gardine.mp3';
/* import audio17 from '../../../sounds/child/de/word/Tapete.mp3';
import audio18 from '../../../sounds/child/de/word/Stoff.mp3';
import audio19 from '../../../sounds/child/de/word/Vorhang.mp3';
import audio20 from '../../../sounds/child/de/word/Fenster.mp3'; */

import audio21 from '../../../sounds/child/de/word/kalt.mp3';
/* import audio22 from '../../../sounds/child/de/word/eisig.mp3';
import audio23 from '../../../sounds/child/de/word/warm.mp3';
import audio24 from '../../../sounds/child/de/word/nass.mp3';
import audio25 from '../../../sounds/child/de/word/trocken.mp3'; */

import audio26 from '../../../sounds/child/de/word/wutend.mp3';
/* import audio27 from '../../../sounds/child/de/word/laut.mp3';
import audio28 from '../../../sounds/child/de/word/zufrieden.mp3';
import audio29 from '../../../sounds/child/de/word/verwirrt.mp3';
import audio30 from '../../../sounds/child/de/word/sauer.mp3'; */

/* import audio31 from '../../../sounds/child/de/word/Pferd.mp3'; */

import audio31 from '../../../sounds/child/de/word/fahne.mp3';

/* import audio32 from '../../../sounds/child/de/word/Kuh.mp3';
import audio33 from '../../../sounds/child/de/word/Gaul.mp3';
import audio34 from '../../../sounds/child/de/word/Esel.mp3';
import audio35 from '../../../sounds/child/de/word/Rind.mp3'; */

import audio36 from '../../../sounds/child/de/word/mohre.mp3';
/* import audio37 from '../../../sounds/child/de/word/Baum.mp3';
import audio38 from '../../../sounds/child/de/word/Blatt.mp3';
import audio39 from '../../../sounds/child/de/word/Stab.mp3';
import audio40 from '../../../sounds/child/de/word/Stange.mp3'; */

import audio41 from '../../../sounds/child/de/word/mude.mp3';
/* import audio42 from '../../../sounds/child/de/word/schwach.mp3';
import audio43 from '../../../sounds/child/de/word/erschopft.mp3';
import audio44 from '../../../sounds/child/de/word/blass.mp3';
import audio45 from '../../../sounds/child/de/word/wach.mp3'; */

import audio46 from '../../../sounds/child/de/word/Angst.mp3';
/* import audio47 from '../../../sounds/child/de/word/Furcht.mp3';
import audio48 from '../../../sounds/child/de/word/Mut.mp3';
import audio49 from '../../../sounds/child/de/word/Stress.mp3';
import audio50 from '../../../sounds/child/de/word/Neid.mp3'; */

import audio51 from '../../../sounds/child/de/word/sanft.mp3';
/* import audio52 from '../../../sounds/child/de/word/zart.mp3';
import audio53 from '../../../sounds/child/de/word/schuchtern.mp3';
import audio54 from '../../../sounds/child/de/word/grob.mp3';
import audio55 from '../../../sounds/child/de/word/klein.mp3'; */

import audio56 from '../../../sounds/child/de/word/Sportler.mp3';
/* import audio57 from '../../../sounds/child/de/word/Rennen.mp3';
import audio58 from '../../../sounds/child/de/word/Athlet.mp3';
import audio59 from '../../../sounds/child/de/word/Spieler.mp3';
import audio60 from '../../../sounds/child/de/word/Trainer.mp3'; */

import audio61 from '../../../sounds/child/de/word/schnell.mp3';
/* import audio62 from '../../../sounds/child/de/word/fruh.mp3';
import audio63 from '../../../sounds/child/de/word/langsam.mp3';
import audio64 from '../../../sounds/child/de/word/rasch.mp3';
import audio65 from '../../../sounds/child/de/word/fluchtig.mp3'; */

import audio66 from '../../../sounds/child/de/word/Schussel.mp3';
/* import audio67 from '../../../sounds/child/de/word/Teller.mp3';
import audio68 from '../../../sounds/child/de/word/Suppe.mp3';
import audio69 from '../../../sounds/child/de/word/Schale.mp3';
import audio70 from '../../../sounds/child/de/word/Tasse.mp3'; */

import audio71 from '../../../sounds/child/de/word/finster.mp3';
/* import audio72 from '../../../sounds/child/de/word/nebelig.mp3';
import audio73 from '../../../sounds/child/de/word/duster.mp3';
import audio74 from '../../../sounds/child/de/word/hell.mp3';
import audio75 from '../../../sounds/child/de/word/gruselig.mp3'; */

import audio76 from '../../../sounds/child/de/word/toll.mp3';
/* import audio77 from '../../../sounds/child/de/word/faul.mp3';
import audio78 from '../../../sounds/child/de/word/fit.mp3';
import audio79 from '../../../sounds/child/de/word/wach.mp3';
import audio80 from '../../../sounds/child/de/word/emsig.mp3'; */

import audio81 from '../../../sounds/child/de/word/Dieb.mp3';
/* import audio82 from '../../../sounds/child/de/word/Rauber.mp3';
import audio83 from '../../../sounds/child/de/word/Tater.mp3';
import audio84 from '../../../sounds/child/de/word/Polizei.mp3';
import audio85 from '../../../sounds/child/de/word/Spur.mp3'; */

import audio86 from '../../../sounds/child/de/word/lecker.mp3';
/* import audio87 from '../../../sounds/child/de/word/laut.mp3';
import audio88 from '../../../sounds/child/de/word/ernst.mp3';
import audio89 from '../../../sounds/child/de/word/stumm.mp3';
import audio90 from '../../../sounds/child/de/word/leise.mp3'; */
const audioFilesObj = [
	{
		mainWord: audio1,
		potentialSynonyms: [
			{synonym: 'audio2'},
			{synonym: 'audio3'},
			{synonym: 'audio4'},
			{synonym: 'audio5'},
		],
	},
	{
		mainWord: audio6,
		potentialSynonyms: [
			{synonym: 'audio7'},
			{synonym: 'audio8'},
			{synonym: 'audio9'},
			{synonym: 'audio10'},
		],
	},
	{
		mainWord: audio11,
		potentialSynonyms: [
			{synonym: 'audio12'},
			{synonym: 'audio13'},
			{synonym: 'audio14'},
			{synonym: 'audio15'},
		],
	},
	{
		mainWord: audio16,
		potentialSynonyms: [
			{synonym: 'audio17'},
			{synonym: 'audio18'},
			{synonym: 'audio19'},
			{synonym: 'audio20'},
		],
	},
	{
		mainWord: audio21,
		potentialSynonyms: [
			{synonym: 'audio22'},
			{synonym: 'audio23'},
			{synonym: 'audio24'},
			{synonym: 'audio25'},
		],
	},
	{
		mainWord: audio26,
		potentialSynonyms: [
			{synonym: 'audio27'},
			{synonym: 'audio28'},
			{synonym: 'audio29'},
			{synonym: 'audio30'},
		],
	},
	{
		mainWord: audio31,
		potentialSynonyms: [
			{synonym: 'audio32'},
			{synonym: 'audio33'},
			{synonym: 'audio34'},
			{synonym: 'audio35'},
		],
	},
	{
		mainWord: audio36,
		potentialSynonyms: [
			{synonym: 'audio37'},
			{synonym: 'audio38'},
			{synonym: 'audio39'},
			{synonym: 'audio40'},
		],
	},
	{
		mainWord: audio41,
		potentialSynonyms: [
			{synonym: 'audio42'},
			{synonym: 'audio43'},
			{synonym: 'audio44'},
			{synonym: 'audio45'},
		],
	},
	{
		mainWord: audio46,
		potentialSynonyms: [
			{synonym: 'audio47'},
			{synonym: 'audio48'},
			{synonym: 'audio49'},
			{synonym: 'audio50'},
		],
	},
	{
		mainWord: audio51,
		potentialSynonyms: [
			{synonym: 'audio52'},
			{synonym: 'audio53'},
			{synonym: 'audio54'},
			{synonym: 'audio55'},
		],
	},
	{
		mainWord: audio56,
		potentialSynonyms: [
			{synonym: 'audio57'},
			{synonym: 'audio58'},
			{synonym: 'audio59'},
			{synonym: 'audio60'},
		],
	},
	{
		mainWord: audio61,
		potentialSynonyms: [
			{synonym: 'audio62'},
			{synonym: 'audio63'},
			{synonym: 'audio64'},
			{synonym: 'audio65'},
		],
	},
	{
		mainWord: audio66,
		potentialSynonyms: [
			{synonym: 'audio67'},
			{synonym: 'audio68'},
			{synonym: 'audio69'},
			{synonym: 'audio70'},
		],
	},
	{
		mainWord: audio71,
		potentialSynonyms: [
			{synonym: 'audio72'},
			{synonym: 'audio73'},
			{synonym: 'audio74'},
			{synonym: 'audio75'},
		],
	},
	{
		mainWord: audio76,
		potentialSynonyms: [
			{synonym: 'audio77'},
			{synonym: 'audio78'},
			{synonym: 'audio79'},
			{synonym: 'audio80'},
		],
	},
	{
		mainWord: audio81,
		potentialSynonyms: [
			{synonym: 'audio82'},
			{synonym: 'audio83'},
			{synonym: 'audio84'},
			{synonym: 'audio85'},
		],
	},
	{
		mainWord: audio86,
		potentialSynonyms: [
			{synonym: 'audio87'},
			{synonym: 'audio88'},
			{synonym: 'audio89'},
			{synonym: 'audio90'},
		],
	},
];

export default audioFilesObj;
