import React from 'react';
import {connect} from 'react-redux';
import './styles.scss';
import Logo from '../../assets/logo-white-2.png';
import {isTablet} from 'react-device-detect';
import {begin} from '../../utils/beforeunload';
import Introduksjon from '../../components/Introduksjon';
import Orddiktat from '../../components/Orddiktat';
import Ordkjedetesten from '../../components/Ordkjedetesten';
import Skrivehastighet from '../../components/Skrivehastighet';
import Leseproven from '../../components/Leseproven';
import Ordforstaelse from '../../components/Ordforstaelse';
import Ferdig from '../../components/Ferdig';
import getTestInfo from '../../api/getTestInfo';
import getUserInfo from '../../api/getUserInfo';
import getUserGrade from '../../api/getUserGrade';
import setAnamneseVersion from '../../api/setAnamneseVersion';
import {setTestCandidateGrade} from '../../actions/index';
import Byttelyd from '../../components/Byttelyd';
import videoTutorial from '../../assets/key-instructions.mp4';
import strings from '../../utils/strings';

const testSteps = [
	Introduksjon,
	Byttelyd,
	Ordkjedetesten,
	Skrivehastighet,
	Ordforstaelse,
	Leseproven,
	Orddiktat,
	Ferdig,
];
var renderRemainingTests = [];

class ScreeningTest extends React.Component {
	constructor() {
		super();
		this.video = videoTutorial;
		this.state = {
			isLoggedIn: false,
			includeAnamnese: false,
			isJumptingTest :false,
			previousStepIndex:null,
			hasPlayedVideoTuorial:false,
			showButton:false,
			
		};
		this.videoRefView = React.createRef();
	}

	componentDidMount() {
	
		getTestInfo().then((results) => {
			const exclusiveTests = results.exclusiveTests;
			const grade = Number(results.grade);
			this.props.onSetTestCandidateGrade(grade);
			const testToContinue = Object.values(exclusiveTests);
			if (testToContinue.length) {
				for (let i = 0; i < testToContinue.length + 1; i++) {
					// eslint-disable-next-line default-case
					switch (testToContinue[i]) {
						case 3:
						renderRemainingTests[0] = testSteps[1];
						break;
						case 2:
						renderRemainingTests[1] = testSteps[2];
						break;
						case 4:
						renderRemainingTests[2] = testSteps[3];
						break;
						case 6:
						renderRemainingTests[3] = testSteps[4];
						break;
						case 5:
						renderRemainingTests[4] = testSteps[5];
						break;
						case 1:
						renderRemainingTests[5] = testSteps[6];
						break;
					
					}
					if (i === testToContinue.length) {
						renderRemainingTests = renderRemainingTests.filter(function (element) {
							return element !== undefined;
						});
						renderRemainingTests.push(Ferdig);
					}
				}
				getUserGrade().then((results) => {
					this.props.onSetTestCandidateGrade(results.grade);
				});
			} else if (grade) {
				renderRemainingTests.push(		
				Byttelyd,
				Ordkjedetesten,
				Skrivehastighet,
				Ordforstaelse,
				Leseproven,
				Orddiktat,
				Ferdig,
				);
				this.props.onSetTestCandidateGrade(grade);
			}

			setAnamneseVersion().then((response) => {
				if (response === 'True') {
					this.setState({includeAnamnese: true});
				} else {
					this.setState({includeAnamnese: false});
				}
			});

			getUserInfo().then((response) => {
				if (response.ok) {
					this.setState({
						isLoggedIn: true,
					});
					begin();
				} else {
					window.location.href = `/`;
				}
			});
		});
	}
	goToTest = ( currentUserStepIndex) => {
		const {currentStepIndex} = this.props;
		this.setState({isJumptingTest:currentUserStepIndex,previousStepIndex:currentStepIndex});

	};
	render() {
		const {isLoggedIn,isJumptingTest,previousStepIndex} = this.state;
		let videoRef;
		if (!isLoggedIn) {
			return null;
		}
		const {currentStepIndex, onSetTestCandidateGrade} = this.props;

		if (renderRemainingTests.length && !isJumptingTest) {
			var CurrentStep = renderRemainingTests[currentStepIndex];
		} else if (isJumptingTest && previousStepIndex===currentStepIndex) {
			const index = Number(this.state.isJumptingTest);
			var CurrentStep = testSteps[index];
			
		} else {
			var CurrentStep = testSteps[currentStepIndex];
		}

		return (
			<div>
				<div className="center">
						{process.env.REACT_APP_ENVIRONMENT !=='production' && (
							
								 <p className="custom-select">
									<select className="GoToTestForm" style={{width:'200px'}}
									onChange={(e) => {
										const currentTextIndex = Number(e.target.value);
										this.goToTest(currentTextIndex);
									}}
										
									>
										
												<option value={1} key={1}>
													{
														strings.tulleordtesten
													}
												</option>
												<option value={2} key={2}>
													{
														strings.ordkjedetesten
													}
												</option>
												<option value={3} key={3}>
													{
														strings.skrivehastighet
													}
												</option>
												<option value={4} key={4}>
													{
														strings.ordforstaelse
													}
												</option>
												<option value={5} key={5}>
													{
														strings.leseproven
													}
												</option>
												<option value={6} key={6}>
													{
														'Wörter schreiben'
													}
												</option>
											
										
									</select>
									
								</p>
						
						)}
					</div>
				<header className="lit-header">
					<h1 className="lit-logo">
						<img
							className="lit-logo__img"
							src={Logo}
							alt="literate-logo"
						/>
					</h1>
				</header>
				{
				(this.state.hasPlayedVideoTuorial || this.state.isJumptingTest) && (
				<div className="lit-wrapper--article text-center">
					<CurrentStep
						includeLongAnamnese={this.state.includeAnamnese}
					/>
				</div>
				)}
				{
				!this.state.hasPlayedVideoTuorial && !this.state.isJumptingTest && (
				<div className="lit-wrapper--article text-center">
					<div className="lit-wrapper tutorial">
					<div className="lit-preview" ref={this.videoRefView}>
					<p className="lit-preview__label">{'Anweisungen'}</p>
					<video
						className="lit-preview__video"
						onEnded={()=>{
							this.setState({
								showButton:true
							});
						}}
						controls
						ref={(ref) => {
							videoRef = ref;
						}}
						onClick={() => {
							videoRef.play();
						}}
						src={this.video}
					/>
				</div>
					{this.state.showButton && (
						<button
						className="lit-btn bg-primary"
						onClick={() => {
							this.setState({
								hasPlayedVideoTuorial:true
							});
							
						}}
					>
						{'Weiter'}
					</button>
					)}
				</div>
				</div>
				)}
				
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {currentStepIndex} = state;

	return {
		currentStepIndex,
	};
};
const mapDispatchToProps = {
	onSetTestCandidateGrade: setTestCandidateGrade,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreeningTest);
