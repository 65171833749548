import React from 'react';
import {connect} from 'react-redux';
import strings from '../../utils/strings';
import classNames from 'classnames';
import {updateObject} from '../../utils/reducerUtils';

import AudioPlayer from '../../components/AudioPlayer';
import FinishSound from '../../components/FinishSound';

import {VARIATION} from '../../constants';
const assignments = [];
var isPlaying = true;
const maxAudioPlayCount = 12;
class Test extends React.Component {
	constructor(props) {
		super(props);
		const variation = VARIATION;
		const language = strings.getLanguage();
		const grade = this.props.candidateGrade;
		const audioFiles = require(`../../assets/testdata/${variation}/${language}/dictation-audio-tutorial`)[`audioFiles_grade${grade}`];
			

		const presStartSound =
			language === 'de'
				? require(`../../assets/sounds/${variation}/${language}/SCR007.mp3`)
				: null;
		this.audioFiles = audioFiles;
		this.presStartSound = presStartSound;
		const results = {};

		if (variation === 'child' && (language === 'se' || language === 'de')) {
			for (let i = 1; i <= 3; i++) {
				//This count may be dependent on the test type
				assignments.push({id: i, value: ''});
			}
		}

		for (const assignment of assignments) {
			results[assignment.id] = assignment.value;
		}

		this.state = {
			results,
			disabledAssignments: {},
			audioPlayCounts: {},
			renderFinalSound: null,
			allowPlayNow:false
		};
	}
	setResults(id, value) {
		if (value.length > 2) {
			const {results} = this.state;

			this.setState({
				results: updateObject(results, {
					[id]: value,
				}),
			});
		}
	}
	getDisabledAssignments() {
		const {results} = this.state;

		const disabledAssignments = {};
		for (let i = 0; i < assignments.length; i++) {
			const assignmentId = assignments[i].id;
			if (results[assignmentId]) {
				disabledAssignments[assignmentId] = true;
			}
		}
		return disabledAssignments;
	}
	render() {
		const {onNextStep, refs} = this.props;

		const {
			audioPlayCounts,
			disabledAssignments,
			results,
			renderFinalSound,
			allowPlayNow
		} = this.state;

		const allResultsHasValues = Object.keys(results).every(
			(key) => results[key]
		);
		const isNextButtonVisible = allResultsHasValues || !this.audioFiles;
		return (
			<div>
				<div className="lit-wrapper--thin lit-test lit-diktat">
					{assignments.map((assignment,index) => {
						const assignmentId = assignment.id;
						let lastRow = false;
						if (assignmentId === 3) {
							lastRow = true;
						}
						const audioFile = this.audioFiles[assignmentId];
						const assignmentAudioPlayCount =
							audioPlayCounts[assignmentId] || 0;
						const isDisabled = disabledAssignments[assignmentId];
						const isAudioDisabled =
							isDisabled ||
							assignmentAudioPlayCount === maxAudioPlayCount;

						let audioPlayerRef;
						return (
							<div
								className={classNames(
									'lit-input lit-revealed',
									{
										'lit-input--disabled': isDisabled,
									}
								)}
								key={assignmentId}
							>
								<AudioPlayer
									disabled={isAudioDisabled}
									id={assignmentId}
									onErrorPlay={()=>{
										console.log('Something went wrong');
									}}
									onPause={() => {
										if (lastRow && allowPlayNow) {
											this.setState({
												renderFinalSound: true,
											});
										}
									}}
									onStart={() => {
										isPlaying = true;
										this.setState({
											audioPlayCounts: updateObject(
												audioPlayCounts,
												{
													[assignmentId]:
														assignmentAudioPlayCount +
														1,
												}
											),
										});
									}}
									ref={(ref) => {
										audioPlayerRef = ref;
									}}
									refs={refs}
									src={audioFile}
								/>
								<input
									className="lit-input__field"
									disabled={isDisabled}
									id={`tf${index+1}`}
									onKeyDown={(event)=>{
									
										if (event.key==='Enter' && index<assignments.length-1)
										{
											document.getElementById(`tf${index+2}`).focus();
										}
										else if (event.key==='Enter' && lastRow && allResultsHasValues) {
											this.setState({allowPlayNow:true,renderFinalSound:true});
										}
	
									}}
									onChange={(ev) => {
										if (isDisabled) {
											return;
										}
										const value = ev.target.value;
										this.setResults(assignmentId, value);
									}}
									onKeyUp={(ev) => {
										if (
											ev.keyCode === 13 ||
											ev.code ===
												'Enter' /* ||
											 ev.keyCode === 9 ||
											ev.code === 'Tab' */
										) {
											const value = ev.target.value;
											this.setResults(
												assignmentId,
												value
											);
										}
									}}
									onFocus={() => {
										const disabledAssignments = this.getDisabledAssignments();
										disabledAssignments[
											assignmentId
										] = false;

										if (assignmentAudioPlayCount === 0) {
											audioPlayerRef.handleStartPlayer();
										}

										this.setState({
											disabledAssignments,
										});
									}}
									placeholder={strings.writeWordHere}
									spellCheck={false}
									autoComplete="off"
									autoCorrect="off"
									autoCapitalize="off"
									type="text"
								/>
							</div>
						);
					})}
				</div>

				<br/>
				
					<div>
						<span>
							{renderFinalSound && (
								<FinishSound
								renderFinalSound={this.state.renderFinalSound}
								text={
									'Klicke auf „Los“, um mit der Aufgabe zu beginnen.'
								}
								audioSrc={this.presStartSound}
							/>
							)}
							
							{isNextButtonVisible && (
							<button
								className="lit-btn lit bg-primary"
								onClick={() => onNextStep(results)}
							>
								{strings.start}
							</button>
								)}
						</span>
					</div>
			
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {candidateGrade} = state;
	return {
		candidateGrade,
	};
};

export default connect(mapStateToProps)(Test);
