import React from 'react';
import {connect} from 'react-redux';
import strings from '../../utils/strings';
// import './styles.scss';
import tutorialVideo from './assets/4006868833093d06696ae39c73ba2ffb.mp4';

import previewVideoNo from './assets/4006868833093d06696ae39c73ba2ffb.mp4';
import previewVideoNn from './assets/4006868833093d06696ae39c73ba2ffb.mp4';

import previewVideoSe from './assets/4006868833093d06696ae39c73ba2ffb.mp4';
import previewVideoDe from './assets/DE_Orddiktat.mp4';

import lowerIntroAudio from '../../assets/sounds/child/de/OrddiktatUpper_Intro.mp3';
import upperIntroAudio from '../../assets/sounds/child/de/1 0 Orddiktat Intro.mp3';

import {VARIATION} from '../../constants';

import AudioPlayer from '../../components/AudioPlayer';

const deIntroAudio={
	'2':lowerIntroAudio,
	'3':lowerIntroAudio,
	'4':lowerIntroAudio,
	'5':upperIntroAudio,
	'6':upperIntroAudio,
	'7':upperIntroAudio,
	'8':upperIntroAudio,
};
class Intro extends React.Component {
	constructor(props) {
		super(props);
		const language = strings.getLanguage();
		const previewVideoObj = {
			no: previewVideoNo,
			nn: previewVideoNn,
			se: previewVideoSe,
			de: previewVideoDe,
		};

		const relevantIntroAudio = deIntroAudio[this.props.candidateGrade.toString()];
		this.orddiktatIntroText = ['2','3','4'].includes(this.props.candidateGrade.toString())?strings.orddiktatIntroChild:strings.orddiktatIntro;
		this.audio = relevantIntroAudio;
		this.video = previewVideoObj[language];
		this.videoRefView = React.createRef();
	}

	render() {
		const {onNextStep} = this.props;

		let videoRef;

		return (
			<div className="lit-intro">
				<p
					style={{
						marginBottom: 0,
					}}
				>
					<AudioPlayer
						autoplay
						onErrorPlay={()=>{
							console.log('Something went wrong');
						}}
						inline
						onStart={() => {
							const scrollActionTime = 23000;

							if (scrollActionTime) {
								setTimeout(() => {
									if (this.videoRefView.current) {
										this.videoRefView.current.scrollIntoView(
											{
												behavior: 'smooth',
												block: 'start',
											}
										);
										videoRef.play();
									}
								}, scrollActionTime);
							}
						}}
						onPause={() => {
							videoRef.play();
						}}
						src={this.audio}
					/>
					{this.orddiktatIntroText}
				</p>
					<p>{strings.orddiktatIntro2}</p>
					<br/>
					<p>{strings.orddiktatIntro3}</p>
				<div className="text-center">
					<div className="lit-preview" ref={this.videoRefView}>
						<p className="lit-preview__label">
							{strings.testLooksLikeThis}
						</p>
						<video
							className="lit-preview__video"
							loop
							ref={(ref) => {
								videoRef = ref;
							}}
							src={this.video}
						/>
					</div>
				</div>
						<p>{strings.subtestStarterTrial}</p>
				<button
					className="lit-btn bg-primary"
					onClick={() => onNextStep()}
				>
					{strings.start}
				</button>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {candidateGrade} = state;
	return {
		candidateGrade,
	};
};

export default connect(mapStateToProps)(Intro);
