import React from 'react';
import {connect} from 'react-redux';

import {updateObject} from '../../utils/reducerUtils';
import strings from '../../utils/strings';
import {isTablet} from 'react-device-detect';
import instructionVideo from './assets/ipad-instruction.mp4';
import instructionVideoPc from './assets/pc-instruction.mp4';
import classNames from 'classnames';
import AudioPlayer from '../../components/AudioPlayer';
import * as deTutorial from '../../assets/testdata/child/de/wordchain-tutorial';
import {VARIATION} from '../../constants';

const whiteSpaceRegex = /\s+/g;

const stripWhiteSpace = (str) => str.replace(whiteSpaceRegex, '');
const removeDuplicateWhiteSpace = (str) => str.replace(whiteSpaceRegex, ' ');
const getNumberOfWhiteSpaces = (str) => {
	const matches = str.match(whiteSpaceRegex);

	if (!matches) {
		return 0;
	}

	return matches.length;
};

const WHITE_SPACE_LIMIT = 3;
const resultsGlobal = [];
const tutorialObj = {
	de: deTutorial
};
const count = 1;
class Test extends React.Component {
	constructor() {
		super();
		const variation = VARIATION;
		const language = strings.getLanguage();
		this.videoIpad = instructionVideo;
		this.videoPc = instructionVideoPc;
		this.tutorial = tutorialObj[strings.getLanguage()];
		this.currentLang = language;
		this.startTheTestSound = require(`../../assets/sounds/${variation}/${language}/2 7 Ordkjedetest-Starter-testen.mp3`);
		this.audio = require(`../../assets/sounds/${variation}/${language}/2 2 Ordkjedetest-her-ser-du-4-ovingsoppgaver.mp3`);
		this.assignments = require(`../../assets/testdata/${variation}/${language}/wordchain-tutorial`).default;
		this.correctAnswerSound = require(`../../assets/sounds/${variation}/${language}/correct_answer_wordchain.mp3`);


		this.audioRefs = {};

		const results = {};

		for (const assignment of this.assignments) {
			results[assignment.id] = assignment.text;
		}

		this.singleAssignment = {
			1: this.tutorial.assignments1,
			2: this.tutorial.assignments2,
			3: this.tutorial.assignments3,
			4: this.tutorial.assignments3,
		};
		this.videoRefView = React.createRef();

		this.state = {
			counter: count,
			results,
			renderStartSound:false,
			showTestPanel: true,
			nextTutorial: false,
			allowForTest:false,
			hasSeenTheVideo: false,
			showButton:false,
		};
	}

	setResults(id, value, index) {
		const {results} = this.state;
		
		let renderNext = false;
		for (let i = 0; i<this.assignments.length; i++) {
			
			if (this.assignments[i].id === id) {
				
			const hasAnsweredFalsely =	this.assignments[i].hasAnsweredFalsely;
				const numberOfWhiteSpaces = getNumberOfWhiteSpaces(value);

				if (this.assignments[i].answer !== value && numberOfWhiteSpaces === WHITE_SPACE_LIMIT ) {
					this.singleAssignment[id][0].hasAnsweredFalsely = true;
					this.assignments[i].hasAnsweredFalsely = true;
					resultsGlobal[index] = {
						index: id,
						correct: this.assignments[i].answer,
						answer: value,
						 isCorrect: false ,
					};
					renderNext = true;

					this.setState({
						results: updateObject(results, {
							[id]: this.assignments[i].answer,
						}),
						nextTutorial:renderNext,
						
					});
				}

				
				else if (this.assignments[i].answer === value && !hasAnsweredFalsely) {
					
					resultsGlobal[index] = {
						index: id,
						correct: this.assignments[i].answer,
						answer: value,
						 isCorrect: true ,
					};
					renderNext = true;
				}
				else {
					resultsGlobal[index] = {
						index: id,
						correct: this.assignments[i].answer,
						answer: value,
						isCorrect: false,
					};
				}
			}

		} 


		this.setState({
			results: updateObject(results, {
				[id]: value,
			}),
			nextTutorial:renderNext,
			
		});
	}
	updateCount() {
		const {results} = this.state;
		this.setState(
			{
				results: updateObject(results, {
					[this.state.choiceId]: this.state.choiceValue,
				}),
			},
			() => {
				this.setState({
					counter: count,
					hideComment: true,
					showTestPanel: false,
					nextTutorial: true,
				});
				
			}
		);

		/* this.scrollToTop(); */
	}
	rednerCorrectAnswerAfterWrong(id,value,index) {
		const {results} = this.state;
		let renderNext = false;
		for (let i = 0; i<this.assignments.length; i++) {
			
			
			if (this.assignments[i].id === id ) {
				const numberOfWhiteSpaces = getNumberOfWhiteSpaces(value);
				if (this.assignments[i].answer !== value && numberOfWhiteSpaces === WHITE_SPACE_LIMIT ) {
					resultsGlobal[index] = {
						index: id,
						correct: this.assignments[i].answer,
						answer: value,
						 isCorrect: false ,
					};
					renderNext = true;

					this.setState({
						results: updateObject(results, {
							[id]: this.assignments[i].answer,
						}),
						nextTutorial:renderNext,
						
					});
					
				}
				
			}

		} 
	}


	render() {
		const {onNextStep} = this.props;
		let videoRef;
		
		const {results} = this.state;
		let allAssignmentsCompleted = true;
		let allAssignmentsAreCorrect = false;
		let correctCounter = 0;
		for (const result in results) {
			if (results.hasOwnProperty(result)) {
				const numberOfWhiteSpaces = getNumberOfWhiteSpaces(
					results[result]
				);
				if (numberOfWhiteSpaces !== WHITE_SPACE_LIMIT) {
					allAssignmentsCompleted = false;
					break;
				}
			}
		}
	for (let i = 0; i <resultsGlobal.length; i++) {
		if (resultsGlobal[i] && resultsGlobal[i].hasOwnProperty('isCorrect')) {
			correctCounter = correctCounter +1;
		}
	}

	if (correctCounter>1 && allAssignmentsCompleted) {
		allAssignmentsAreCorrect = true;
	}
	

		return (
			<div className="lit-test lit-word-chain">
				{this.state.hasSeenTheVideo && (
				<>
				<div className="lit-wrapper--thin tutorial">
					<div className="lit-word-chains">
						{this.singleAssignment[this.state.counter].map(
						(assignment,i) => {
							const {audio, answer, count, id, text,mainSound, mainText, hasAnsweredFalsely} = assignment;
							let onTheFocus = false;
							let completedAssignment = false;
							let isCorrect = false;
							const numberOfWhiteSpaces = getNumberOfWhiteSpaces(
								results[id]
							);

							
							if (numberOfWhiteSpaces === WHITE_SPACE_LIMIT && !hasAnsweredFalsely) {
								completedAssignment = true;
								isCorrect = results[id] === answer;
							}
							if (numberOfWhiteSpaces === WHITE_SPACE_LIMIT && hasAnsweredFalsely) {
								completedAssignment = true;
								isCorrect = false;
							}

							const onChange = (ev) => {
								let value = ev.target.value;
								value = value.trim();
								value = removeDuplicateWhiteSpace(value);

								const hasUserEnteredOtherCharacters =
									stripWhiteSpace(value) !== text;
								const numberOfWhiteSpaces = getNumberOfWhiteSpaces(
									value
								);
								if (
									hasUserEnteredOtherCharacters ||
									numberOfWhiteSpaces > WHITE_SPACE_LIMIT
								) {
									return;
								}

								this.setResults(id, value, i);
								this.rednerCorrectAnswerAfterWrong(id,value,i);
							};
							const onFocus = (ev) => {
								onTheFocus = true;
								const inputElement = document.getElementById(i);

								setTimeout(() => {
								inputElement.focus();
								inputElement.setSelectionRange(0, 0);
								  }, 0);
								
							};

							return (
								
					<div className="input-wrapper" key={id}>
				{this.state.counter <4 && (
				<>
					<p>
					<AudioPlayer
						autoplay
						onErrorPlay={()=>{
							console.log('Something went wrong');
						}}
						inline
						
						refs={this.audioRefs}
						src={mainSound}
					/>
					{mainText}
				</p>
				<br/>
									<div
										className={classNames({
											'lit-input inline': true,
											focused: onTheFocus,
											completed: completedAssignment,
											answered:hasAnsweredFalsely,
										})}
									>
										<label className="lit-input__label tiny lit-input__label--count">
											{id}
										</label>
										{!hasAnsweredFalsely && (

										<input
										id={i}
										autoFocus={i === 0}
										className={classNames({
											'lit-input__field': true,
										})}
										onChange={onChange}
										onFocus={onFocus}
										spellCheck={false}
										value={results[id]}
										/>
										)}
										{hasAnsweredFalsely && (
											<input
											id={i}
											autoFocus={i === 0}
											disabled={completedAssignment}
											className={classNames({
												'lit-input__field': true,
											})}
											style={{wordSpacing:'12px', color:'black', backgroundColor:'#f4f5f5'}}
											onFocus={onFocus}
											spellCheck={false}
											value={results[id]}
											/>
											)}
									</div>
									<br/>
									{this.currentLang === 'de' &&
										completedAssignment && (
											<p className="comment">
												{isCorrect ? (
													<span>
														{
															<AudioPlayer
																autoplay
																onErrorPlay={()=>{
																	console.log('Something went wrong');
																}}
																inline
																refs={
																	this
																		.audioRefs
																}
																onComplete={() => {
																	if (allAssignmentsAreCorrect) {
																		this.setState({
																			renderStartSound:true,
																			counter:count+1
																		});
																	}
																
																
																}}
																src={
																	this
																		.correctAnswerSound
																}
															/>
														}
														{
															strings.ordkjedeHeltRiktig
														}
													</span>
												) : (
													<span>
														<AudioPlayer
															autoplay
															onErrorPlay={()=>{
																console.log('Something went wrong');
															}}
															inline
															refs={
																this.audioRefs
															}
															onComplete={() => {
																
																if(allAssignmentsAreCorrect) {
																	this.setState({
																		renderStartSound:true
																	});
																}
															}}
															src={audio}
														/>

														{strings.formatString(
															strings.ordkjedeIkkeRiktig,
															count
														)}
														<strong>
															{answer
																.split(' ')
																.join(', ')}
														</strong>
													</span>
												)}
											</p>
										)}
									{this.currentLang !== 'de' &&
										completedAssignment &&
										i > 0 && (
											<p className="comment">
												{isCorrect ? (
													<span>
														{/* <AudioPlayer
														autoplay
														inline
													/> */}
														{
															strings.ordkjedeHeltRiktig
														}
													</span>
												) : (
													<span>
														<AudioPlayer
															// autoplay
															onErrorPlay={()=>{
																console.log('Something went wrong');
															}}
															inline
															refs={
																this.audioRefs
															}
															src={audio}
														/>

														{strings.formatString(
															strings.ordkjedeIkkeRiktig,
															count
														)}
														<strong>
															{answer
																.split(' ')
																.join(', ')}
														</strong>
													</span>
												)}
											</p>
										)}
										</> )}
								</div>
							);
						})}
					</div>
					
					
					{this.state.counter >3 && (
						<>
						<p className="instruction">
							<AudioPlayer
								autoplay
								onErrorPlay={()=>{
									console.log('Something went wrong');
								}}
								inline
								onComplete={()=>{
									this.setState({
										allowForTest:true
									});
								}}
								refs={this.audioRefs}
								src={this.startTheTestSound}
							/>
							{strings.ordkjedeStarterTesten}
						</p>
						<br/>
					<p>	{strings.ordkjedeStarterTesten2}
						</p>
</>
					)}
					
					{ this.state.counter >3 && this.state.allowForTest && (
						<button
							className="lit-btn bg-primary"
							onClick={() => onNextStep(results)}
						>
							{strings.start}
						</button>
					)}
				</div>
				{this.state.nextTutorial && count < 4 && (
					<button
						className="lit-btn bg-primary"
						onClick={() => {
							this.setState((prevState) => ({
								counter: prevState.counter + 1,
								nextTutorial: false,
							}));
							
							
						}}
					>
						{'Weiter'}
					</button>
				)}
				</>
				)}
				{isTablet && !this.state.hasSeenTheVideo && (
				<>
				<div className="lit-test lit-word-chain">
				<div className="lit-wrapper--thin tutorial">
				<div className="lit-preview" ref={this.videoRefView}>
					<p className="lit-preview__label">{'Anweisungen'}</p>
					
					<video
						className="lit-preview__video"
						onEnded={()=>{
							this.setState({
								showButton:true
							});
						}}
						ref={(ref) => {
							videoRef = ref;
						}}
						onClick={() => {
							videoRef.play();
						}}
						src={this.videoIpad}
					/>
				</div>
				{this.state.showButton && (
				<button
						className="lit-btn bg-primary"
						onClick={() => {
							this.setState({
								hasSeenTheVideo:true
							});
						}}
					>
						{'Weiter'}
					</button>
				)}
					</div>
					</div>
				</>
				
				)}
				{!isTablet && !this.state.hasSeenTheVideo && (
				<>
				<div className="lit-test lit-word-chain">
				<div className="lit-wrapper tutorial">
				<div className="lit-preview" ref={this.videoRefView}>
					<p className="lit-preview__label">{'Anweisungen'}</p>
					
					<video
						className="lit-preview__video"
						onEnded={()=>{
							this.setState({
								showButton:true
							});
						}}
						controls
						ref={(ref) => {
							videoRef = ref;
						}}
						onClick={() => {
							videoRef.play();
						}}
						src={this.videoPc}
					/>
				</div>
				{this.state.showButton && (
				<button
						className="lit-btn bg-primary"
						onClick={() => {
							this.setState({
								hasSeenTheVideo:true
							});
							
						}}
					>
						{'Weiter'}
					</button>
				)}
					</div>
					</div>
				</>
				
				)}
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps)(Test);
