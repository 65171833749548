import getFetchOptions from '../utils/getFetchOptions';
import {ENDPOINT_URL} from '../constants';

export const verifyNoAuthDe = (testKey,isTablet) => {
	return fetch(
		`${ENDPOINT_URL}/verifyNoAuthDe`,
		getFetchOptions({
			method: 'POST',
			body: JSON.stringify({testKey,isTablet}),
		})
	).then((response) => {
		if (response.ok) {
			return response.json();
		}
		return Promise.reject();
	});
};

export default verifyNoAuthDe;
