const pauseAndReset = (audioElem) => {
	if (!audioElem) {
		return;
	}
	try {
		audioElem.pause();
	}
	catch (e) {
		console.log('something went wrong in pauseAndReset',e);
	}
	audioElem.currentTime = 0;
};

const playPause = (audioElem, otherAudioElemsObject = {}, errorPlay) => {

	var isPlaying = audioElem.currentTime > 0 && !audioElem.paused && !audioElem.ended 
	&& audioElem.readyState > audioElem.HAVE_CURRENT_DATA;
	
	if (audioElem.paused) {
		Object.keys(otherAudioElemsObject).forEach((key) => {
			pauseAndReset(otherAudioElemsObject[key]);
		});

		if (!isPlaying) {
			audioElem.play().catch((e)=>{
				console.log('something went wrong in playPause, trying callback...',e);
					  errorPlay();
					  audioElem.play().catch((error)=>{
						errorPlay();
						console.log('something went wrong again in playPause',error);
					  });
				  });
		  }
	} else {
		pauseAndReset(audioElem);
	}
};

export default playPause;
