import React from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import FinishSound from '../../components/FinishSound';
import {isTablet} from 'react-device-detect';
import {updateObject} from '../../utils/reducerUtils';

import AudioPlayer from '../../components/AudioPlayer';

import strings from '../../utils/strings';
import {VARIATION} from '../../constants';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVolumeHigh} from '@fortawesome/free-solid-svg-icons';

const assignments = [];

const maxAudioPlayCount = 2;

class Test extends React.Component {
	constructor(props) {
		super(props);
		const variation = VARIATION;
		const language = strings.getLanguage();
		const grade = this.props.candidateGrade;
		const audioFiles = require(`../../assets/testdata/${variation}/${language}/dictation-audioFiles`)[`audioFiles_grade${grade}`];
		const size = Object.keys(audioFiles).length;
		this.taskIsOver =
			language === 'de'
				? require(`../../assets/sounds/${variation}/${language}/DictatOverBluffStart.mp3`)
				: null;
		this.audioFiles = audioFiles;

		const results = {};

			for (let i = 1; i <= size; i++) {
				//This count may be dependent on the test type
				assignments.push({id: i, value: ''});
			}
		

		for (const assignment of assignments) {
			results[assignment.id] = assignment.value;
		}

		this.state = {
			results,
			disabledAssignments: {},
			audioPlayCounts: {},
			renderFinalSound: null,
			allowPlayNow:false,
			showWordAudio: false,
			currentClickedIndex: null,
			showChoiceAudio: false,
			currentChoiceIndex: null,
			showQuestionPlayButton: true,
			showChoicePlayButton: true,
		};
	}

	setResults(id, value) {
		const {results} = this.state;

		this.setState({
			results: updateObject(results, {
				[id]: value,
			}),
		});
	}

	getDisabledAssignments() {
		const {results} = this.state;

		const disabledAssignments = {};
		for (let i = 0; i < assignments.length; i++) {
			const assignmentId = assignments[i].id;
			if (results[assignmentId]) {
				disabledAssignments[assignmentId] = true;
			}
		}
		return disabledAssignments;
	}

	render() {
		const {onNextStep, refs} = this.props;

		const {
			audioPlayCounts,
			disabledAssignments,
			results,
			renderFinalSound,
			allowPlayNow
		} = this.state;

		const allResultsHasValues = Object.keys(results).every(
			(key) => results[key]
		);
		const isNextButtonVisible = allResultsHasValues;

		return (
			<div className="lit-wrapper--thin lit-test lit-diktat">
				{!isTablet &&
				assignments.map((assignment,index) => {
					const assignmentId = assignment.id;
					const audioFile = this.audioFiles[assignmentId];
					const assignmentAudioPlayCount =
						audioPlayCounts[assignmentId] || 0;
					let lastRow = false;
					if (assignmentId === assignments.length) {
						lastRow = true;
					}
					const isDisabled = disabledAssignments[assignmentId];
					const isAudioDisabled =
						isDisabled ||
						assignmentAudioPlayCount === maxAudioPlayCount;

					let audioPlayerRef;
					return (
						<div
							className={classNames('lit-input lit-revealed', {
								'lit-input--disabled': isDisabled,
							})}
							key={assignmentId}
						>
							<AudioPlayer
								disabled={isAudioDisabled}
								id={assignmentId}
								onErrorPlay={() => {
									console.log('Something went wrong');
									this.setState({
										showQuestionPlayButton: true,
										currentClickedIndex: null,
										showChoicePlayButton: true,
										audioPlayCounts: updateObject(
											audioPlayCounts,
											{
												[assignmentId]:
													assignmentAudioPlayCount -
													1,
											}
										),
									});
								}}
								onPause={() => {
									if (lastRow && allowPlayNow) {
										this.setState({
											renderFinalSound: true,
										});
									}
								}}
								onStart={() => {
									this.setState({
										audioPlayCounts: updateObject(
											audioPlayCounts,
											{
												[assignmentId]:
													assignmentAudioPlayCount +
													1,
											}
										),
									});
								}}
								ref={(ref) => {
									audioPlayerRef = ref;
								}}
								refs={refs}
								src={audioFile}
							/>
							<input
								className="lit-input__field"
								disabled={isDisabled}
								id={`tf${index+1}`}
								onKeyDown={(event)=>{
									
									if (event.key==='Enter' && index<assignments.length-1)
									{
										document.getElementById(`tf${index+2}`).focus();
									}
									else if (event.key==='Enter' && lastRow && allResultsHasValues) {
										this.setState({allowPlayNow:true,renderFinalSound:true});
									}

								}}
								onChange={(ev) => {
									if (isDisabled) {
										return;
									}
									const value = ev.target.value;
								
									this.setResults(assignmentId, value);
								}}
								onFocus={() => {
									const disabledAssignments = this.getDisabledAssignments();
									disabledAssignments[assignmentId] = false;

									if (assignmentAudioPlayCount === 0) {
										audioPlayerRef.handleStartPlayer();
									}

									this.setState({
										disabledAssignments,
									});
								}}
								placeholder={strings.writeWordHere}
								spellCheck={false}
								autoComplete="off"
								autoCorrect="off"
								autoCapitalize="off"
								type="text"
							/>
						</div>
					);
				})}

			{isTablet &&
				assignments.map((assignment,index) => {
					const assignmentId = assignment.id;
					const mainIndex = index+1;
					const audioFile = this.audioFiles[assignmentId];
					const assignmentAudioPlayCount =
						audioPlayCounts[assignmentId] || 0;
					let lastRow = false;
					if (assignmentId === assignments.length) {
						lastRow = true;
					}
					const isDisabled = disabledAssignments[assignmentId];
					const isAudioDisabled =
						isDisabled ||
						assignmentAudioPlayCount === maxAudioPlayCount;

					let audioPlayerRef;
					return (
						<div
								style={{
									display: 'flex',
									marginTop: '50px',
								}}
								className={classNames(
									'lit-input lit-revealed',
									{
										'lit-input--disabled': isDisabled,
									}
								)}
								key={assignmentId}
							>
								<div
									className=""
									style={{
										height: '50px',
										width: '50px',
										marginRight: '20px',
									}}
								>
							{this.state.showQuestionPlayButton && (
													<FontAwesomeIcon
														size="lg"
														className={
															!isAudioDisabled
																? 'lit-play-button-ordiktat'
																: 'lit-play-button-ordiktat-disabled '
														}
														icon={faVolumeHigh}
														
														onClick={() => {
															if (!isAudioDisabled) {
															this.setState({
																showWordAudio: true,
																showQuestionPlayButton: false,
																currentClickedIndex:
																	mainIndex,
																showChoicePlayButton: false,
															});
														}
														}}
													/>
												)}
												{!this.state.showQuestionPlayButton &&
										this.state.currentClickedIndex !==
											mainIndex && (
											<FontAwesomeIcon
												size="lg"
												className="lit-play-button-ordiktat-disabled"
												icon={faVolumeHigh}
											/>
										)}

							{this.state.showWordAudio && this.state.currentClickedIndex ===mainIndex && (					
							<div>
								<AudioPlayer
								disabled={isAudioDisabled}
								id={assignmentId}
								autoplay
								onErrorPlay={() => {
									console.log(
										'Something went wrong'
									);
									this.setState({
										showQuestionPlayButton: true,
										currentClickedIndex:
											null,
										showChoicePlayButton: true,
										audioPlayCounts:
											updateObject(
												audioPlayCounts,
												{
													[assignmentId]:
														assignmentAudioPlayCount -
														1,
												}
											),
									});
								}}
								onComplete={() => {
									this.setState({
										showQuestionPlayButton: true,
										currentClickedIndex:
											null,
										showChoicePlayButton: true,
									});
								}}
								onPause={() => {
									if (lastRow && allowPlayNow) {
										this.setState({
											renderFinalSound: true,
										});
									}
								}}
								onStart={() => {
									this.setState({
										audioPlayCounts: updateObject(
											audioPlayCounts,
											{
												[assignmentId]:
													assignmentAudioPlayCount +
													1,
											}
										),
									});
								}}
								ref={(ref) => {
									audioPlayerRef = ref;
								}}
								refs={refs}
								src={audioFile}
							/>
							</div>
							)} 
								</div>
							<input
								className="lit-input__field"
								disabled={isDisabled}
								spellCheck={false}
								autoComplete="off"
								autoCorrect="off"
								autoCapitalize="off"
								id={`tf${index+1}`}
								onKeyDown={(event)=>{
									
									if (event.key==='Enter' && index<assignments.length-1)
									{
										document.getElementById(`tf${index+2}`).focus();
									}
									else if (event.key==='Enter' && lastRow && allResultsHasValues) {
										this.setState({allowPlayNow:true,renderFinalSound:true});
									}

								}}
								onChange={(ev) => {
									if (isDisabled) {
										return;
									}
									const value = ev.target.value;
								
									this.setResults(assignmentId, value);
								}}
								onFocus={() => {
									const disabledAssignments = this.getDisabledAssignments();
									disabledAssignments[assignmentId] = false;

									/* if (assignmentAudioPlayCount === 0) {
										audioPlayerRef.handleStartPlayer();
									} */
									if (!isAudioDisabled) {
										this.setState({
											showWordAudio: true,
											showQuestionPlayButton: false,
											currentClickedIndex:
												mainIndex,
											showChoicePlayButton: false,
										});
									}

									this.setState({
										disabledAssignments,
									});
								}}
								placeholder={strings.writeWordHere}
								spellCheck={false}
								autoComplete="off"
								autoCorrect="off"
								autoCapitalize="off"
								type="text"
							/>
							
						</div>
					);
				})}
			
					<div>
						<span>
							{renderFinalSound && (
							<FinishSound
								renderFinalSound={this.state.renderFinalSound}
								text={strings.Dictation_is_Finished}
								audioSrc={this.taskIsOver}
							/>
							)}
							
							{isNextButtonVisible && (
							<button
								className="lit-btn lit bg-primary"
								onClick={() => onNextStep(results)}
							>
								{strings.done}
							</button>
							)}
						</span>
					</div>
			
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {candidateGrade} = state;
	return {
		candidateGrade,
	};
};

export default connect(mapStateToProps)(Test);
